<template>
    <div class="pvhFactory">
        <basic-container>
            <!--data 数据源 v-model：对象行 -->
            <avue-crud :data="data" ref="crud" v-model="obj" :option="setData.option" :page.sync="page"
                       @current-change="currentChange"
                       @refresh-change="refreshChange">
                <template slot="menuLeft" style="width: calc(100% - 80px);">
                    <el-row :gutter="20">
                        <el-col :xs="24" :sm="16" :md="16" :lg="19" :xl="20" class="fromOutDiv">
                            <el-input v-model.trim="standardCode" placeholder="请输入标准编码" clearable size="small"
                                      style="width:200px !important;marginRight: 5px;"></el-input>
                            <el-input v-model.trim="parameterCode" placeholder="请输入参数编码" clearable size="small"
                                      style="width:200px !important;marginRight: 5px;"></el-input>
                        </el-col>
                        <el-col :xs="24" :sm="8" :md="8" :lg="5" :xl="4"
                                style=" text-align: right; align-self: flex-end;">
                            <el-button type="primary" icon="el-icon-plus" size="mini" :title="$t('New')" plain
                                       @click="addInfo"> {{$t('New')}}
                            </el-button>
                            <el-button :title="$t('Refresh')" type="primary" icon="el-icon-search" size="mini" plain
                                       @click="refreshChange"> {{$t('Refresh')}}
                            </el-button>
                        </el-col>
                    </el-row>
                </template>
            </avue-crud>
        </basic-container>
    </div>
</template>
<script>
    import {list, standardCodelist, feedbacklist} from "@/api/canonicalParameter";

    export default {
        data() {
            return {
                standardCode: "",
                parameterCode: "",
                obj: {},
                standardCodeData: [],
                feedbackData: [],
                page: {
                    currentPage: 1,
                    total: 0,
                    layout: "total,pager,prev, next",
                    background: false,
                    pageSize: 10
                },
                data: [],
            }
        },
        computed: {
            setData() {
                return {
                    option: {
                        page: true,
                        menuTitle: this.$t('OPERATION'),//操作按钮
                        menu: false,
                        align: 'center',
                        columnBtn: false,//列冻结、隐藏按钮
                        simplePage: false,
                        menuAlign: 'center',
                        editBtn: false,
                        delBtn: false,
                        addBtn: false,      //是否显示添加按钮，默认true
                        refreshBtn: false,
                        column: [
                            {
                                label: '标准编号',
                                prop: 'standardCode',//
                                type: "select",//下拉框调用qualityFeedback/selectPage接口 根据公司ID
                                props: {
                                    label: 'code',
                                    value: 'code'
                                },
                                dicData: this.standardCodeData,
                            },
                            {
                                label: '参数编号',
                                prop: 'parameterCode',
                                type: "select",//下拉框调用qualityFeedback/selectPage接口 根据公司ID
                                props: {
                                    label: 'code',
                                    value: 'code'
                                },
                                dicData: this.feedbackData,
                            },
                            {
                                label: '标准描述',
                                prop: 'specificStandards'
                            },
                            {
                                label: '最大值',
                                prop: 'maxValue',
                                precision: 2,
                                type: 'number'
                            },
                            {
                                label: '最小值',
                                prop: 'minValue',
                                precision: 2,
                                type: 'number'
                            },
                        ]
                    }
                }
            }
        },
        created() {
            const xx = {
                pageNo: 1,
                pageSize: 100,
                total: 100,
            }
            //code
            standardCodelist(xx).then(res => {
                console.log(66, res);
                this.standardCodeData = res.data.data.items;
            });
            feedbacklist(xx).then(res => {
                this.feedbackData = res.data.data.items;
            })
            this.getList()
        },
        methods: {
            refreshChange() {
                this.getList()
            },
            //新增
            addInfo() {
                this.$router.push({path: '/quality/feedbackStandardAdd', query: {sign: 'add', ID: ''}})
            },
            getList() {
                const xx = {
                    pageNo: this.page.currentPage,
                    pageSize: this.page.pageSize,
                    total: this.page.total,
                    standardCode: this.standardCode,
                    parameterCode: this.parameterCode,
                }
                list(xx).then(res => {
                    if (res.data.data == null) {
                        this.data = [];
                        this.page.total = 0;
                    } else {
                        // debugger
                        this.page.total = res.data.data.total
                        this.data = res.data.data.items
                    }
                    console.log(res, 'res')
                }).catch(erro => {
                    console.log(erro);
                })
            },
            currentChange(val) {
                this.page.currentPage = val
                this.getList()
            }
        }
    }
</script>
<style scoped>
</style>
